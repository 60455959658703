import { ApolloClient, ApolloClientOptions, createHttpLink } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';

import { _getToken } from '../../helpers/authentication/authentication';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { isProduction } from '../../helpers/environment/isProduction';
import { buildGraphqlHeaders, GRAPHQL_URI, CORS_OPTIONS } from '../rest/shared';

import { cacheTypePolicies } from './cacheTypePolicies';
import { getRetryLink } from './shared';

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  ...CORS_OPTIONS,
});

const authLink = setContext(async (_, context) => {
  const { headers, getAuthToken } = context;
  // The ideal situation is that the getAuthToken function should be passed in the context (
  // coming from useAuth), as it allows for the user's auth state to be updated correctly when the
  // the token is found to be invalid. That said, it's not always possible to do, for example if the
  // query is coming from outside the React render tree. For this case we fallback to using
  // `_getToken`.
  const asyncHeaders = await buildGraphqlHeaders(getAuthToken || _getToken);
  return { headers: { ...asyncHeaders, ...headers } };
});

export const initializeGraphqlClient = (
  options: Partial<ApolloClientOptions<NormalizedCacheObject>> = {},
  useAuthLink = true,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    connectToDevTools: !isProduction() && isBrowser(),
    cache: new InMemoryCache(cacheTypePolicies),
    link: useAuthLink
      ? authLink.concat(getRetryLink('rutilus')).concat(httpLink)
      : getRetryLink('rutilus').concat(httpLink),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    ...options,
  });
