import { ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';

import { getRutilusGraphqlClient } from '../../api/graphql/getRutilusGraphqlClient';
import { AnalyticsProvider } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { AuthProvider } from '../../contexts/AuthContext';
import { CookiePermissionsProvider } from '../../contexts/CookiePermissionsContext/CookiePermissionsContext';
import { GeolocationProvider } from '../../contexts/GeolocationContext/GeolocationContext';
import { SessionProvider } from '../../contexts/SessionContext';
import { _hasAccessToken } from '../../helpers/authentication/shared';
import { getReactErrorBoundary } from '../../helpers/errorHandling';
import { IPageMetadata } from '../../interfaces/nextjs';
import { ClientSideInitializer } from '../ClientSideInitializer/ClientSideInitializer';
import { PageViewHandler } from '../PageViewHandler/PageViewHandler';
import { Facebook } from '../Scripts/Facebook/Facebook';
import { GoogleTagManager } from '../Scripts/GoogleTagManager/GoogleTagManager';
import { Hotjar } from '../Scripts/Hotjar/Hotjar';

interface IProps {
  pageProps: IPageMetadata;
  children?: ReactNode;
  blockDatadogRum?: boolean;
}

const ErrorBoundary = getReactErrorBoundary();
const client = getRutilusGraphqlClient({ serverMode: 'static' });

export const AppLogic = ({ pageProps, children, blockDatadogRum = false }: IProps) => {
  return (
    <ErrorBoundary>
      <GeolocationProvider>
        <CookiePermissionsProvider>
          <GoogleTagManager />
          <Hotjar />
          <Facebook />
          <AnalyticsProvider blockDatadogRum={blockDatadogRum} {...pageProps}>
            <ApolloProvider client={client}>
              {/* We start off unauthenticated by default and let the authentication be updated on the client */}
              <AuthProvider isAuthenticated={_hasAccessToken()}>
                <SessionProvider>
                  <ClientSideInitializer>
                    <PageViewHandler
                      _pageId={pageProps._pageId}
                      _page={pageProps._page}
                      _pageTitle={pageProps._pageTitle}
                    >
                      {children}
                    </PageViewHandler>
                  </ClientSideInitializer>
                </SessionProvider>
              </AuthProvider>
            </ApolloProvider>
          </AnalyticsProvider>
        </CookiePermissionsProvider>
      </GeolocationProvider>
    </ErrorBoundary>
  );
};
