import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

import { isBrowser } from '../../helpers/environment/isBrowser';

import { cacheTypePolicies } from './cacheTypePolicies';
import { initializeGraphqlClient } from './initializeGraphqlClient';

let browserClient: ApolloClient<NormalizedCacheObject> | undefined;

export function getRutilusGraphqlClient({
  serverMode = undefined,
}: {
  initialCache?: NormalizedCacheObject;
  serverMode?: 'static' | 'ssr';
} = {}): ApolloClient<NormalizedCacheObject> {
  // CSR
  if (isBrowser()) {
    if (!browserClient) {
      browserClient = initializeGraphqlClient({
        cache: new InMemoryCache(cacheTypePolicies),
      });
    }

    return browserClient;
  }

  if (!serverMode) {
    throw new Error('serverMode is required');
  }

  // SSR
  return initializeGraphqlClient(
    {
      ssrMode: true,
    },
    serverMode === 'ssr',
  );
}
